<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" />
        <v-row>
          <v-col
            cols="12"
            :md="showHelp ? 12 : 6"
            sm="3"
            class="pr-0"
            v-for="item in views"
            :key="item.id"
          >
            <v-card
              color="primary"
              dark
              @click="whereToPush(item)"
              height="100px"
            >
              <v-card-title class="text-h5">
                {{ item.title }}
              </v-card-title>

              <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConfiguracionAppBenef",
  components: { PageHeader, Ayuda },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.setPermisos();
  },
  data() {
    return {
      title: enums.titles.CONFIGURACION_APP_BENEF,
      showHelp: false,
      showExpand: false,
      showIcon: true,
      allowedActions: null,
      optionCode: enums.webSiteOptions.CONFIGURACION_APLICACION_MOVIL,
      views: [],
    };
  },
  methods: {
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.webSiteOptions.ENLACES_UTILES:
            this.views.push({
              id: 0,
              title: x.title,
              subtitle:
                "Permite crear, editar y eliminar un enlace para la aplicación.",
              path: "EnlacesUtilesApp",
            });
            break;
          case enums.webSiteOptions.MENU_TABS:
            this.views.push({
              id: 1,
              title: x.title,
              subtitle:
                "Permite habilitar, deshabilitar y modificar nombres de las opciones de menú y tabs.",
              path: "MenuTabs",
            });
            break;
          case enums.webSiteOptions.FORMATOS_EMAIL_APP:
            this.views.push({
              id: 2,
              title: x.title,
              subtitle:
                "Configuración de los formatos de mails para la creación de usuarios y recuperación de contraseñas.",
              path: "FormatosEmailApp",
            });
            break;
          case enums.webSiteOptions.PARAMETROS_GENERALES_APP:
            this.views.push({
              id: 3,
              title: x.title,
              subtitle: "Permite configurar los parámetros de la aplicación.",
              path: "ParametrosGeneralesApp",
            });
            break;
          case enums.webSiteOptions.BOTON_DE_PAGO:
            this.views.push({
              id: 4,
              title: x.title,
              subtitle: "Configuración del botón de pago de la App.",
              path: "BotonDePago",
            });
            break;
          case enums.webSiteOptions.PARENTESCOS_APP:
            this.views.push({
              id: 5,
              title: x.title,
              subtitle:
                "Permite indicar qué familiares podrá ver el afiliado que utiliza la App según parentesco y edad.",
              path: "ParentescosApp",
            });
            break;
          case enums.webSiteOptions.CONFIGURACION_APP:
            this.views.push({
              id: 6,
              title: x.title,
              subtitle:
                "Configuración de distintas Apps para casos de multiempresas con distinta imagen.",
              path: "ConfiguracionApps",
            });
            break;
          case enums.webSiteOptions.TIPOS_DE_EXPEDIENTES:
            this.views.push({
              id: 7,
              title: x.title,
              subtitle:
                "Permite crear, editar y eliminar un tipo de expediente habilitado para iniciar trámites.",
              path: "TiposDeExpedientesApp",
            });
            break;
          case enums.webSiteOptions.TOKEN:
            this.views.push({
              id: 8,
              title: x.title,
              subtitle: "Token de seguridad en autorización de prestaciones.",
              path: "Token",
            });
            break;
          case enums.webSiteOptions.CREDENCIAL_DIGITAL_APP:
            this.views.push({
              id: -1,
              title: x.title,
              subtitle:
                "Diseño y configuración de la credencial que se muestra en la App.",
              path: "mvc",
              params: `${window.location.origin}/redirto?id=ConfiguracionApp/CredencialDigitalConfig`,
            });
            break;
        }
      });
    },
    whereToPush(item) {
      if (item.id !== -1) {
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
          opaque: true,
        });
      }
      if (item.id === -1) {
        this.$router.push({
          name: item.path,
          params: { url: encodeURIComponent(item.params) },
        });
      } else {
        this.$router.push({
          name: item.path,
        });
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>

<style scoped>
::v-deep .text-h5 {
  font-size: 1.35rem !important;
  font-weight: 450;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
